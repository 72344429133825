import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'





Vue.use(VueRouter)


  const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },/*
  {
    path: '/ElektraWeb',
    name: 'ElektraWeb',
    component: () => import('../views/ElektraWeb.vue'),
  },
  {
    path: '/Fmcg',
    name: 'Fmcg',
    component: () => import('../views/Fmcg.vue'),
  },
  {
    path: '/Eservices',
    name: 'Eservices',
    component: () => import('../views/Eservices.vue'),
    children:[
      {
        name: 'EserviceFFP',
        path:'EserviceFFP',
        component: () => import('../views/EserviceFFP.vue'),
      },
      {
        name: 'EserviceBE',
        path:'EserviceBE',
        component: () => import('../views/EserviceBE.vue'),
      },
      {
        name: 'EserviceCM',
        path:'EserviceCM',
        component: () => import('../views/EserviceCM.vue'),
      },
      {
        name: 'EservicePOS',
        path:'EservicePOS',
        component: () => import('../views/EservicePOS.vue'),
      },
      {
        name: 'EserviceRM',
        path:'EserviceRM',
        component: () => import('../views/EserviceRM.vue'),
      },
      {
        name: 'EserviceSPA',
        path:'EserviceSPA',
        component: () => import('../views/EserviceSPA.vue'),
      },
  ],
  },
  {
    path: '/Fam',
    name: 'Fam',
    component: () => import('../views/Fam.vue'),
  },
  {
    path: '/Cortex',
    name: 'Cortex',
    component: () => import('../views/Cortex.vue'),
  },
  {
    path: '/Scp',
    name: 'Scp',
    component: () => import('../views/Scp.vue'),
  },
  {
    path: '/Strings',
    name: 'Strings',
    component: () => import('../views/Strings.vue'),
  },
  {
    path: '/Ota',
    name: 'Ota',
    component: () => import('../views/Ota.vue'),
  },
  {
    path: '/Psg',
    name: 'Psg',
    component: () => import('../views/Psg.vue'),
  },
  {
    path: '/Scout',
    name: 'Scout',
    component: () => import('../views/Scout.vue'),
  },
  {
    path: '/FreeCooling',
    name: 'FreeCooling',
    component: () => import('../views/FreeCooling.vue'),
  },
  {
    path: '/Bbs',
    name: 'Bbs',
    component: () => import('../views/Bbs.vue'),
  },
  {
    path: '/Fmw',
    name: 'Fmw',
    component: () => import('../views/Fmw.vue'),
  },
  {
    path: '/MicroCredit',
    name: 'MicroCredit',
    component: () => import('../views/MicroCredit.vue'),
  },
  {
    path: '/Vedubox',
    name: 'Vedubox',
    component: () => import('../views/Vedubox.vue'),
  },
  {
    path: '/OneStore',
    name: 'OneStore',
    component: () => import('../views/OneStore.vue'),
  },
  {
    path: '/Roomsware',
    name: 'Roomsware',
    component: () => import('../views/Roomsware.vue'),
  },*/
]

const router = new VueRouter({
  routes,
})

export default router
