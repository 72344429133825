<template>
  <div style="margin-bottom: 60px">
    <b-navbar
      class="fixed-top"
      type="dark"
      toggleable="lg"
      style="background: #002046; padding-top: 12px; padding-bottom: 12px"
    >
      <div class="container">
        <b-navbar-brand href="/" v-on:click="scrollToTop()">
          <span style="font-weight: bold; color: white; cursor: pointer">
            <img src="@/assets/mainLogo.svg" style="width: 130px" alt=""
          /></span>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav right>
          <b-navbar-nav class="ml-auto" style="font-weight: 500">
            <b-nav-item v-on:click="scroll(1)">
              <span class="un"> {{ $t("solutions") }} </span>
            </b-nav-item>
<!--            <b-nav-item v-on:click="scroll(2)">
              <span class="un"> {{ $t("partners") }} </span>
            </b-nav-item>-->
            <b-nav-item v-on:click="scroll(3)">
              <span class="un"> {{ $t("team") }} </span>
            </b-nav-item>
            <b-nav-item v-on:click="scroll(4)">
              <span class="un mr-3"> {{ $t("contact") }} </span>
            </b-nav-item>
            <b-nav-item>
              <img
                @click="$i18n.locale = 'ge'"
                src="https://img.icons8.com/color/48/000000/georgia-circular.png"
                style="width: 20px; height: 20px"
              />
              |
              <img
                @click="$i18n.locale = 'en'"
                src="https://img.icons8.com/color/48/000000/great-britain-circular.png"
                style="width: 20px; height: 20px"
              />
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {};
  },

  async mounted() {},

  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },

  components: {},
  props: {},

  computed: {},
};
</script>

<style scoped>
.un {
  color: white;
  display: inline-block;
  padding-bottom: 2px;
  background-image: linear-gradient(white, white);
  background-position: 0 100%; /*OR bottom left*/
  background-size: 0% 2px;
  background-repeat: no-repeat;
  transition: background-size 0.3s, background-position 0s 0.3s; /*change after the size immediately*/
}

.un:hover {
  background-position: 100% 100%; /*OR bottom right*/
  background-size: 100% 2px;
}
</style>
<style>
.navbar-toggler {
  border: none !important;
}
</style>
