<template>
  <div class="" id="app">
    <Navbar />
    <Showcase
      v-if="
        $router.currentRoute.name !== 'ElektraWeb' &&
        !$router.currentRoute.path.includes('Eservices')
      "
    />
    <transition name="fade">
      <router-view></router-view>
    </transition>
<!--    <Partners
      v-if="
        $router.currentRoute.name !== 'ElektraWeb' &&
        !$router.currentRoute.path.includes('Eservices')
      "
    />-->
    <Team
      v-if="
        $router.currentRoute.name !== 'ElektraWeb' &&
        !$router.currentRoute.path.includes('Eservices')
      "
    />
    <Form />
    <Footerend />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Showcase from "./components/Showcase.vue";
import Team from "./components/Team.vue";
import Partners from "./components/Partners.vue";
import Form from "./components/Form.vue";
import Footerend from "./components/Footerend.vue";

export default {
  name: "App",
  data() {
    return {};
  },

  components: {
    Navbar,
    Showcase,
    Team,
    Partners,
    Form,
    Footerend,
  },

  computed: {},
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #002046;
  background: #f1f0f0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
