<template>
  <div class="container" style="padding-top: 90px; padding-bottom: 70px" id="3">
    <div class="">
      <div>
        <h2 style="font-weight: 700; margin-bottom: 20px; text-align: center">
          {{ $t("teamHeader") }}
        </h2>
        <p style="text-align: center; font-weight: 500">{{ $t("teamPar") }}</p>
      </div>
      <div class="row justify-content-center" style="text-align: center">
        <div class="flip-card col-xl-4 col-md-4 col-sm-12 mt-4">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img
                src="@/assets/team/Osman1.jpg"
                style="
                  width: 215px;
                  height: 210px;
                  margin-bottom: 50px;
                  margin-top: 30px;
                "
                alt=""
              />
              <h4 style="font-weight: 550; margin-bottom: 30px">Osman Turan</h4>
            </div>
            <div class="flip-card-back">
              <div>
                <h4
                  style="
                    font-weight: 550;
                    margin-bottom: 30px;
                    padding-top: 20px;
                  "
                >
                  Osman Turan
                </h4>
                <p style="font-weight: 400">Gurtiad Georgia, President</p>
                <p style="font-weight: 400">Ucell Uzbekistan, CEO</p>
                <p style="font-weight: 400">Ncell Nepal, CEO</p>
                <p style="font-weight: 400">Geocell Georgia, CEO</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flip-card col-xl-4 col-md-4 col-sm-12 mt-4">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img
                src="@/assets/eka.jpeg"
                style="
                  width: 195px;
                  height: 210px;
                  margin-bottom: 50px;
                  margin-top: 30px;
                "
                alt=""
              />
              <h4 style="font-weight: 550; margin-bottom: 30px">
                Eka Natsvlishvili
              </h4>
            </div>
            <div class="flip-card-back">
              <div>
                <h4
                  style="
                    font-weight: 550;
                    margin-bottom: 30px;
                    padding-top: 20px;
                  "
                >
                  Eka Natsvlishvili
                </h4>
                <p style="font-weight: 400">Digisense Georgia, Sales Manager</p>
                <p style="font-weight: 400">
                  Silknet Georgia, Major Account Sales Manager
                </p>
                <p style="font-weight: 400">
                  Geocell Georgia, Corporate Sales Executive
                </p>
                <p style="font-weight: 400" class="mx-2">
                  Geocell Georgia, Premium&Corporate Customers Service Division
                  Head
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Team",
  data() {
    return {
      show: true,
      show2: true,
    };
  },
  async mounted() {},

  methods: {},

  components: {},
  props: {},

  computed: {},
};
</script>

<style scoped>
.pad {
  background: white;
  color: #002046;
  padding: 15px;
  height: 360px;
  box-shadow: 0px 10px 20px 2px #bbb8b8;
  font-weight: 550;
}
.pad:hover {
  background: #002046;
  color: white;
}

.pad1 {
  background: white;
  color: #002046;
  padding: 15px;
  height: 360px;
  box-shadow: 0px 10px 20px 2px #bbb8b8;
  font-weight: 550;
}

.flip-card {
  background-color: none;
  height: 360px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0px 10px 20px 2px #bbb8b8;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: white;
  color: #002046;
}

.flip-card-back {
  background-color: #002046;
  color: white;
  transform: rotateY(180deg);
}
</style>
